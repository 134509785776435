<template>
  <report filename="Ventas" report="sales">
    <template #filters="{ apply, loading, setData, setColumns, filterKey }">
      <report-filters
        :filter-key="filterKey"
        :loading="loading"
        :movement-type-code="[1]"
        :selected-columns.sync="arSelectedHeaders"
        hide-movements-type
        hide-payment-type
        @apply="apply"
        @change="setData"
        @columns="setColumns"
        @reset="onSetInitFilterData"
      >
        <template #default="{ filters }">
          <v-row>
            <v-col cols="12" lg="4" md="6">
              <customer-select v-model="filters.invoice_customer" multiple />
            </v-col>
          </v-row>
        </template>
      </report-filters>
    </template>
  </report>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import type { DataTableHeader } from "@/mixins/DataTableMixin";
import Report from "@/modules/reports/components/Report.vue";
import ReportFilters from "@/modules/reports/components/ReportFilters.vue";
import CustomerSelect from "@/modules/customers/components/CustomerSelect.vue";
import { AppModule } from "@/store/app";
import { EventBus } from "@/services/event-bus";
import ReportFilterMixin from "@/modules/reports/mixins/ReportFilterMixin";

@Component({
  components: { CustomerSelect, ReportFilters, Report },
})
export default class SalesReport extends Mixins(ReportFilterMixin) {
  arSelectedHeaders: DataTableHeader["value"][] = [
    "code",
    "name",
    "quantity",
    "currency",
    "total_price_value",
  ];

  get obCurrencies() {
    return AppModule.currencies;
  }

  get obDefaultCurrency() {
    return this.obCurrencies.find({ is_default: true });
  }

  setDefaultFilters() {
    this.setFilter("signed", 1);
    this.setFilter("notRejected", 1);
    this.setFilter("movementTypeCode", [1]);
    this.unsetFilter("invoiceMovementType");
  }

  onSetInitFilterData() {
    this.setFilter("currency", 0);

    if (this.obDefaultCurrency) {
      this.setFilter("currency_convert", this.obDefaultCurrency.id);
    }

    this.setDefaultFilters();
  }

  onSetFilterData() {
    this.setDefaultFilters();
  }

  created() {
    this.setDefaultHeaders();
    this.removeHeader([
      "created_at",
      "invoice_type.short_name",
      "order_number",
      "customer_id",
      "customer.firm.doc_formatted",
      "taxes",
    ]);
    this.addHeader({ text: "report.code", value: "code" }, 0);
    this.addHeader({ text: "report.name", value: "name" }, 1);
    this.addHeader({ text: "report.quantity", value: "quantity" }, 2);
    EventBus.on("report.before.apply", this.onSetFilterData);
  }

  beforeDestroy() {
    EventBus.off("report.before.apply", this.onSetFilterData);
  }
}
</script>
